.addresses-display-block {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin: unit(2);
  overflow: hidden;

  @include max(767px) {
    flex-direction: column;
  }

  &__address {
    flex: 1;
    box-sizing: border-box;
    background: black;
    color: white;
    overflow: hidden;
    display: flex;
    height: 725px;

    @include max(767px) {
      height: 540px;
    }

    &:not(:first-child) {
      margin-top: 0;
      margin-left: unit(2);

      @include max(767px) {
        margin-top: unit(2);
        margin-left: 0;
      }
    }

    &:hover {
      img {
        opacity: 0.4;
        transition: opacity 0.3s ease-in;
      }
    }

    &__background {
      width: 100%;
      height: 100%;

      @include max(767px) {
        width: auto;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        vertical-align: top;
        opacity: 0.3;
        transition: opacity 0.3s ease-out;

        @include max(767px) {
          width: auto;
        }
      }
    }

    &__content {
      box-sizing: border-box;
      position: absolute;
      padding: 120px;
      width: calc(50% - #{unit(1)});
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      color: white;

      @include max(1279px) {
        padding: 9%;
      }

      @include max(767px) {
        width: 100%;
        height: calc(50% - #{unit(1)});
        padding: 30px;
      }

      * {
        margin: 0;
      }

      &__region {
        opacity: 0.34;
        font-weight: 500;
        font-size: 20px;
        letter-spacing: -0.2px;
      }

      &__city {
        opacity: 0.9;
        font-weight: 600;
        font-size: 36px;
        letter-spacing: -0.2px;
        line-height: 44px;
        margin: 20px 0 40px;
      }

      &__address {
        opacity: 0.9;
        font-size: 20px;
        letter-spacing: -0.2px;
        line-height: 26px;
        margin-bottom: 40px;
      }

      label {
        font-weight: bold;
        line-height: 42px;
      }

      &__name,
      a {
        opacity: 0.8;
        color: white;
        text-decoration: none;
        line-height: 26px;
      }
    }
  }
}
