.services-list-display-block {
  box-sizing: border-box;
  margin: unit(2);
  background: black;
  color: white;
  overflow: hidden;
  padding: 180px 90px;

  @include max(767px) {
    padding: 80px 20px;
  }

  h2 {
    opacity: 0.9;
    font-size: 56px;
    font-weight: 500;
    letter-spacing: -0.8px;
    line-height: 64px;
    margin-bottom: 80px;

    @include max(767px) {
      margin-bottom: 60px;
    }
  }

  &__services {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    &__service {
      width: 100%;
      display: flex;
      justify-content: space-between;

      &:not(:first-child) {
        margin-top: 160px;
      }

      &:nth-child(even) {
        flex-direction: row-reverse;
      }

      @include max(767px) {
        flex-direction: column;

        &:not(:last-child) {
          margin-bottom: 40px;
        }

        &:not(:first-child) {
          margin-top: 80px;
        }

        &:nth-child(even) {
          flex-direction: column;
        }
      }

      > * {
        width: calc(50vw - #{unit(1)} - 90px - 50px);

        @include max(767px) {
          width: 100%;
        }
      }

      picture {
        display: block;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          opacity: 0.5;

          @include max(767px) {
            width: calc(100vw - #{unit(1)});
            margin-left: -#{unit(4)};
          }
        }
      }

      &__content {
        max-width: 540px;

        h3 {
          opacity: 0.9;
          font-weight: 600;
          font-size: 36px;
          letter-spacing: -0.2px;
          line-height: 44px;
          margin-top: 0;
          @include max(767px) {
            margin-top: 36px;
          }
        }

        > p {
          opacity: 0.9;
          font-size: 20px;
          letter-spacing: -0.2px;
          line-height: 26px;
          margin-bottom: 40px;
        }

        > div {
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          max-height: 150px;
          max-width: 400px;

          span {
            opacity: 0.6;
            font-weight: 500;
            font-size: 16px;
            letter-spacing: -0.16px;
            line-height: 26px;
            width: 200px;
            padding-bottom: 10px;

            @include max(767px) {
              max-width: calc(50vw - #{unit(1)});
            }
          }
        }
      }
    }
  }
}
