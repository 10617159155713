.hero-display-block {
  position: relative;
  width: 100%;
  height: 800px;
  background-color: black;
  overflow: hidden;

  @include max(767px) {
    height: 100vh;
  }

  &__background {
    img {
      width: 100%;
      height: 800px;
      object-fit: cover;
      vertical-align: top;
      opacity: 0.7;

      @include max(767px) {
        height: 100vh;
      }
    }
  }

  &__content {
    box-sizing: border-box;
    position: absolute;
    top: 0;
    width: 100vw;
    height: 100%;
    display: flex;
    padding: 0 100px 120px;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    color: white;

    @include max(767px) {
      max-width: none;
      padding: 20px 40px 40px 20px;
    }

    &__text {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      @include max(767px) {
        flex-direction: column;
        align-items: flex-start;
      }

      * {
        margin: 0;
      }

      .statement {
        font-size: 50px;
        letter-spacing: 0;
        line-height: 58px;
        max-width: 70%;

        @include max(767px) {
          max-width: none;
          font-size: 44px;
          letter-spacing: 0;
          line-height: 52px;
        }

        &.medium {
          font-size: 40px;
          letter-spacing: 0.2px;
          line-height: 46px;

          @include max(767px) {
            font-size: 26px;
            letter-spacing: -0.4px;
            line-height: 32.8px;
          }
        }
      }

      .customer-name {
        font-weight: 600;
        font-size: 22px;
        letter-spacing: 0;
        line-height: 28px;
        @include max(767px) {
          margin-top: 40px;
        }
      }
    }

    img {
      display: none;

      @include max(767px) {
        display: block;
        margin: 40px 0;
      }
    }
  }
}
