.contacts-display-block {
  box-sizing: border-box;
  margin: unit(2);
  background: black;
  color: white;
  overflow: hidden;
  padding: 180px 190px;
  display: flex;
  flex-direction: row;

  @include max(767px) {
    padding: 80px 20px;
    flex-direction: column;
  }

  &__preamble {
    flex: 1;
    font-size: 28px;
    letter-spacing: -0.43px;
    line-height: 34.8px;

    @include max(767px) {
      font-size: 26px;
    }
  }

  &__contacts {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 0;
    margin-left: 150px;

    @include max(767px) {
      margin-top: 80px;
      margin-left: 0;
    }

    &__contact {
      margin-right: 40px;
      @include max(767px) {
        &:not(:last-child) {
          margin-bottom: 40px;
        }
      }

      label,
      a {
        font-size: 16px;
        letter-spacing: 0;
      }

      label {
        font-weight: bold;
        line-height: 42px;
      }

      a {
        opacity: 0.8;
        color: white;
        line-height: 26px;
        display: block;
        text-decoration: none;
      }
    }
  }
}
