.two-columns-display-block {
  box-sizing: border-box;
  margin: unit(2);
  padding: 120px 90px;
  color: white;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  &.background {
    &-black {
      background-color: black;
    }

    &-white {
      background-color: white;
      color: black;
    }
  }

  @include max(767px) {
    padding: 60px 20px;
  }

  &__column {
    width: calc(50% - 45px);
    display: flex;

    @include max(767px) {
      width: 100%;

      &:first-child:not(.is-empty) {
        margin-bottom: 40px;
      }
    }

    * {
      margin: 0;
    }

    picture,
    video {
      box-sizing: border-box;
      padding: unit(2);
      display: block;
      width: calc(100% + 90px + 45px);
      @include max(767px) {
        width: calc(100% + 40px);
      }

      img {
        width: 100%;
        object-fit: cover;
        vertical-align: top;
      }
    }

    &:first-child {
      picture,
      video {
        margin: -120px -45px -120px -90px;
        padding-right: unit(1);

        @include max(767px) {
          margin: -60px -20px -20px;
          padding-right: unit(2);
          padding-bottom: unit(1);
        }
      }
    }

    &:last-child {
      picture,
      video {
        margin: -120px -90px -120px -45px;
        padding-left: unit(1);

        @include max(767px) {
          margin: -20px -20px -60px;
          padding-left: unit(2);
          padding-top: unit(1);
        }
      }
    }

    &__content {
      h3,
      p {
        max-width: 520px;
      }

      h3 {
        font-weight: normal;
        font-size: 28px;
        letter-spacing: -0.43px;
        line-height: 34.8px;
      }

      p {
        font-size: 20px;
        letter-spacing: -0.2px;
        line-height: 26px;
      }
    }
  }
}
