.three-columns-display-block {
  box-sizing: border-box;
  margin: unit(2);
  background-color: black;
  padding: 160px 90px;
  color: white;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @include max(767px) {
    padding: 20px;
  }

  &__column {
    width: 360px;

    @include max(1359px) {
      width: calc(50% - 30px);
    }

    @include max(767px) {
      width: 100%;
    }

    h3 {
      font-weight: 500;
      font-size: 26px;
      letter-spacing: -0.2px;
      line-height: 46px;
    }

    div {
      opacity: 0.9;
      font-size: 20px;
      letter-spacing: -0.2px;
      line-height: 26px;
    }
  }
}
