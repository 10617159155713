.image-display-block {
  box-sizing: border-box;
  margin: unit(2);

  img {
    width: 100%;
    object-fit: cover;
    vertical-align: top;
  }
}
