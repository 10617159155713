@mixin min($width) {
  @media screen and (min-width: $width) {
    @content;
  }
}

@mixin max($width) {
  @media screen and (max-width: $width) {
    @content;
  }
}

html,
body {
  font-family: "HK Grotesk";
  background-color: $background-color;
  -webkit-font-smoothing: antialiased;
}
