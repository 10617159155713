$unit: 5px;
$border-radius: 2px;
$background-color: #1f1f1f;

@function unit($x) {
  @return $unit * $x;
}

// Hamburger settings
$hamburger-layer-border-radius: 0;
$hamburger-layer-color: white;
$hamburger-active-layer-color: white;
$hamburger-layer-width: 20px;
$hamburger-layer-height: 2px;
$hamburger-layer-spacing: 3px;
$hamburger-types: (minus);
$hamburger-padding-x: 0;
$hamburger-padding-y: 0;
$hamburger-hover-opacity: 1;

@import "../../../node_modules/hamburgers/_sass/hamburgers/hamburgers.scss";

@import "fonts";
@import "base";

@import "components/page-header";
@import "components/page-footer";
@import "components/button";

@import "blocks/home-page-hero";
@import "blocks/services-display";
@import "blocks/cases-display";
@import "blocks/contacts-display";
@import "blocks/addresses-display";
@import "blocks/services-list-display";
@import "blocks/callout-display";
@import "blocks/hero-display";
@import "blocks/image-display";
@import "blocks/three-columns-display";
@import "blocks/two-columns-display";
@import "blocks/media-display";
@import "blocks/case-introductory-display";
@import "blocks/slideshow";
