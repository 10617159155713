.callout-display-block {
  padding: 160px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include max(767px) {
    padding: 80px 20px;
  }

  h2 {
    font-size: 50px;
    font-weight: 400;
    color: #ffffff;
    letter-spacing: -0.2px;
    line-height: 58px;
    margin-top: 0;
  }

  a {
    display: block;
    text-decoration: none;
    opacity: 0.9;
    font-size: 20px;
    color: #ffffff;
    letter-spacing: -0.2px;
    text-align: center;
    line-height: 26px;

    &:hover {
      opacity: 0.7;
      transition: opacity 0.2s ease-in;
    }
  }
}
