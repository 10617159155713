%sns-dot {
  content: "";
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 6px;
  margin-right: 15px;
}

.page-footer {
  display: flex;
  flex-direction: row;
  margin: 10px;
  background: black;
  color: white;
  padding: 120px 0;

  @include max(1100px) {
    flex-direction: column;
    padding: 80px 0;
  }

  &__logo-tagline {
    width: 50%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include max(1300px) {
      width: 40%;
      padding: 0 50px;
    }

    @include max(1100px) {
      width: 100%;
      align-items: flex-start;
    }

    & > div {
      width: 100%;
      max-width: 400px;
    }

    img {
      max-width: 100%;
    }

    p {
      margin-top: 30px;
      font-size: 16px;
      font-weight: 300;
      line-height: (22 / 16);
    }
  }

  &__sections {
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    width: 50%;

    @include max(1300px) {
      width: 70%;
    }

    @include max(1100px) {
      width: 100%;
      padding: 50px;
    }

    @include max(750px) {
      flex-direction: column;
    }
  }

  &__section {
    width: 50%;

    @include max(750px) {
      width: 100%;
    }

    h3 {
      font-size: 20px;
      font-weight: 500;
      letter-spacing: -0.2px;
      line-height: (26 / 20);
      color: #525252;
      margin-bottom: 30px;
    }

    p {
      font-size: 20px;
      font-weight: 500;
      letter-spacing: -0.2px;
      line-height: (26 / 20);
    }
  }

  &__sns {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      margin: 10px 0;

      &.ig::before {
        @extend %sns-dot;
        background-image: radial-gradient(
          circle at 30% 107%,
          #fdf497 0%,
          #fdf497 5%,
          #fd5949 45%,
          #d6249f 60%,
          #285aeb 90%
        );
      }

      &.li::before {
        @extend %sns-dot;
        background-color: #0177b5;
      }

      &.me::before {
        @extend %sns-dot;
        background-color: #018f69;
      }
    }

    a {
      color: white;
      text-decoration: none;
      font-size: 20px;
      font-weight: 500;
      letter-spacing: -0.2px;
      line-height: (26 / 20);
    }
  }
}
