.services-display-block {
  box-sizing: border-box;
  margin: unit(2);
  background: black;
  color: white;
  overflow: hidden;

  &__header {
    padding: 150px 150px 50px;

    @include max(960px) {
      padding: 50px 50px 20px;
    }

    @include max(750px) {
      padding: 20px;
    }

    h2 {
      font-size: 56px;
      font-weight: 600;
      line-height: (64 / 56);
      letter-spacing: -0.8px;
      margin: 0 0 30px;
      max-width: 850px;

      @include max(750px) {
        font-size: 32px;
        margin: 20px 0 0;
      }
    }

    p {
      font-size: 26px;
      font-weight: 500;
      letter-spacing: -0.4x;
      line-height: (34 / 26);
      margin: 1em 0;
      max-width: 750px;

      @include max(750px) {
        font-size: 20px;
      }
    }
  }

  &__services {
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    display: flex;
    flex-direction: row;
    padding-bottom: 150px;

    @include max(960px) {
      padding-bottom: 50px;
    }

    @include max(750px) {
      padding-bottom: 20px;
    }

    &::after {
      content: " ";
      display: block;
      width: 50px;
      flex-shrink: 0;
    }
  }

  &__service {
    flex-shrink: 0;
    width: 500px;
    margin-right: 100px;

    @include max(960px) {
      width: 380px;
      margin-right: 50px;
    }

    @include max(750px) {
      width: 280px;
      margin-right: 30px;
    }

    &:first-child {
      margin-left: 150px;

      @include max(960px) {
        margin-left: 50px;
      }

      @include max(750px) {
        margin-left: 20px;
      }
    }

    img {
      width: 100%;
      height: 280px;
      object-fit: cover;
      vertical-align: top;
    }

    h3 {
      font-size: 26px;
      font-weight: 600;
      letter-spacing: -0.2px;
      line-height: 34px;
      margin: 50px 0 20px;
    }

    p {
      font-size: 16px;
      font-weight: 300;
      line-height: 22px;
      margin: 1em 0;
    }

    .button {
      margin-top: 50px;

      @include max(750px) {
        margin-top: 20px;
      }
    }
  }
}
