.cases-display-block {
  margin: 10px 0;
  background-color: black;
  color: white;

  &__case {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 100vh;

    @include max(750px) {
      flex-direction: column-reverse;
      height: auto;
    }

    > a {
      color: inherit;
      text-decoration: inherit;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    max-width: 600px;
    padding: 0 150px;

    @include max(1350px) {
      max-width: 500px;
    }

    @include max(1000px) {
      padding: 0 50px;
    }

    @include max(920px) {
      max-width: 450px;
    }

    @include max(750px) {
      padding: 10px 20px;
    }

    h3 {
      font-size: 20px;
      font-weight: 600;
      letter-spacing: 0.4px;
      line-height: 26px;
      margin: 0;
    }

    h2 {
      font-size: 80px;
      font-weight: 500;
      letter-spacing: -1px;
      line-height: 84px;
      margin: 20px 0 60px;

      @include max(750px) {
        font-size: 40px;
        line-height: 1.1;
        margin: 0.25em 0;
      }
    }

    p {
      font-size: 20px;
      letter-spacing: -0.2px;
      line-height: 26px;

      @include max(750px) {
        font-size: 16px;
        line-height: 1.4;
        letter-spacing: 0;
      }
    }

    ul {
      margin: 30px 0;
      padding: 0;
      list-style: none;
    }

    li {
      display: inline-block;
      margin-right: 20px;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0.4px;
      line-height: 22px;
    }
  }

  &__img {
    box-sizing: border-box;
    padding: 10px;
    width: 45%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    transition: 0.5s opacity ease;

    @include max(1440px) {
      width: 550px;
    }

    @include max(1340px) {
      width: 450px;
    }

    @include max(1000px) {
      width: 350px;
    }

    @include max(750px) {
      width: 100%;
      position: relative;
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;

      @include max(750px) {
        height: 50vh;
      }
    }
  }

  @include min(750px) {
    &--fixed &__img {
      position: fixed;
    }

    &__case--passed &__img {
      opacity: 0;
    }
  }
}
