@font-face {
  font-family: "HK Grotesk";
  font-weight: 300;
  src: url("../fonts/HKGrotesk-Light.woff2") format("woff2"),
    url("../fonts/HKGrotesk-Light.woff") format("woff");
}

@font-face {
  font-family: "HK Grotesk";
  font-weight: normal;
  src: url("../fonts/HKGrotesk-Regular.woff2") format("woff2"),
    url("../fonts/HKGrotesk-Regular.woff") format("woff");
}

@font-face {
  font-family: "HK Grotesk";
  font-weight: 500;
  src: url("../fonts/HKGrotesk-Medium.woff2") format("woff2"),
    url("../fonts/HKGrotesk-Medium.woff") format("woff");
}

@font-face {
  font-family: "HK Grotesk";
  font-weight: bold;
  src: url("../fonts/HKGrotesk-Bold.woff2") format("woff2"),
    url("../fonts/HKGrotesk-Bold.woff") format("woff");
}

@font-face {
  font-family: "HK Grotesk";
  font-weight: 600;
  src: url("../fonts/HKGrotesk-SemiBold.woff2") format("woff2"),
    url("../fonts/HKGrotesk-SemiBold.woff") format("woff");
}
