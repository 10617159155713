.case-introductory-display-block {
  box-sizing: border-box;
  margin: unit(2);
  background: black;
  color: white;
  overflow: hidden;
  padding: 120px 90px;
  display: flex;
  justify-content: space-between;

  @include max(767px) {
    padding: 60px 20px;
    flex-direction: column;
  }

  * {
    margin: 0;
  }

  h3 {
    max-width: 700px;
    margin-right: 100px;
    font-size: 28px;
    font-weight: normal;
    letter-spacing: -0.43px;
    line-height: 34.8px;

    @include max(767px) {
      margin-right: 0;
    }
  }

  &__services {
    display: flex;
    flex-direction: column;
    min-width: 200px;
    margin-right: 100px;

    @include max(767px) {
      margin-right: 0;
      margin-top: 40px;
    }

    h4 {
      font-weight: 600;
      font-size: 26px;
      letter-spacing: -0.2px;
      line-height: 46px;
    }

    &__service {
      h5 {
        font-weight: bold;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 26px;
        margin: 25px 0 10px;
      }

      &:first-of-type h5 {
        margin-top: 15px;
      }

      div {
        display: flex;
        flex-direction: column;

        span {
          opacity: 0.8;
          font-size: 16px;
          letter-spacing: 0;
          line-height: 26px;
        }
      }
    }
  }
}
