.button {
  display: inline-block;
  padding: 0 15px;
  height: 40px;
  background: none;
  color: white;
  border: 1px solid white;
  border-radius: $border-radius;

  font-size: 14px;
  font-weight: 500;
  line-height: 40px;
  text-decoration: none;

  &:hover {
    color: rgba(white, 0.8);
  }
}
