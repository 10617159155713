.page-header {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 150px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 60px;

  @include max(1023px) {
    padding: 0 20px;
    height: 100px;
  }

  &--inverted {
    color: white;

    path,
    polygon {
      fill: white;
    }
  }

  &__logo {
    position: absolute;
    left: 50%;
    top: unit(4);
    transform: translateX(-50%) scale(0.9);

    @include max(1023px) {
      transform: translateX(-50%) scale(0.75);
      transform-origin: top;
    }
  }

  &__menu {
    @include max(1023px) {
      display: none;
    }

    ul {
      padding: 0;
      margin: 0;
      list-style: none;
    }

    li {
      display: inline-block;
      margin-right: 40px;

      &:last-child {
        margin-right: 0;
      }
    }

    a {
      font-size: 20px;
      font-weight: 600;
      line-height: 26px;
      text-decoration: none;
      color: inherit;
    }
  }

  &__lang-picker {
    $picker-width: 14px;
    position: absolute;
    right: unit(12);

    @include max(1023px) {
      top: unit(6);
      left: unit(4);
    }

    select {
      appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none;

      font-weight: 600;
      font-size: 16px;
      letter-spacing: 0.2px;
      color: white;
      text-transform: capitalize;

      background: none;
      border: none;
      border-radius: 0;
      background-repeat: no-repeat;
      background-position: right 50%;

      padding-right: calc(30px + #{$picker-width});

      cursor: pointer;
      box-shadow: 0 0 0 5px rgba(white, 0);
      transition: 0.2 background-color ease, 0.2 box-shadow ease;

      &:focus {
        background-color: transparent;
        box-shadow: none;
        outline: none;
      }

      &:hover,
      &:active {
        background-color: rgba(white, 0.1);
        box-shadow: 0 0 0 10px rgba(white, 0.1);
      }

      @include max(1023px) {
        padding-right: calc(20px + #{$picker-width});
      }
    }
  }

  &__mobile-menu {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: black;
    z-index: 1;

    @include max(1023px) {
      padding: 20px;
    }

    @keyframes fade-in {
      from {
        opacity: 0;
      }

      to {
        opacity: 1;
      }
    }

    &.is-active {
      animation: fade-in 0.3s ease;

      @include max(1023px) {
        display: block;
      }
    }

    img {
      width: 100px;
      margin-top: 10px;
    }

    ul {
      list-style: none;
      padding: 0;
      margin-top: 125px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    a {
      display: block;
      margin-top: 30px;
      font-size: 34px;
      text-align: center;
      color: #ffffff;
      text-decoration: none;
    }

    li {
      opacity: 0;
    }

    @keyframes fade-down-in {
      from {
        opacity: 0;
        transform: translateY(-10px);
      }

      to {
        opacity: 1;
        transform: translateY(0);
      }
    }

    @for $i from 1 through 10 {
      &.is-active li:nth-child(#{$i}) {
        animation: fade-down-in 0.5s ease;
        animation-delay: (100 * $i) * 1ms;
        animation-fill-mode: forwards;
      }
    }
  }

  &--inverted &__menu {
    color: white;
  }
}

.hamburger {
  display: none;
  position: absolute;
  top: unit(6);
  right: unit(4);
  z-index: 3;

  @include max(1023px) {
    display: block;
    margin: -20px;
    padding: 20px;
  }

  &-inner {
    height: 0;
    &,
    &:before,
    &:after {
      background-color: white;
    }
  }

  &.is-active {
    .hamburger-inner {
      height: 2px;
    }
  }

  .page-header--inverted + .page-header__mobile-menu + & {
    &:not(.is-active) .hamburger-inner {
      &,
      &:before,
      &:after {
        background-color: white;
      }
    }
  }

  &:active,
  &:focus {
    outline: none;
    box-shadow: none;
  }
}
