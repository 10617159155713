.home-page-hero-block {
  position: relative;
  width: 100%;
  overflow: hidden;

  &__background {
    img {
      width: 100%;
      height: 100vh;
      object-fit: cover;
      vertical-align: top;
    }
  }

  &__content {
    box-sizing: border-box;
    position: absolute;
    top: 0;
    height: 100%;
    left: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: white;

    @include max(1200px) {
      left: 50px;
    }

    @include max(760px) {
      left: 0;
      width: 100%;
      padding: 50px;
    }

    @include max(540px) {
      padding: 30px;
    }

    h1 {
      font-size: 84px;
      line-height: 84px;
      letter-spacing: -1px;
      font-weight: 500;
      width: 850px;
      max-width: 100%;
      margin: 0;

      @include max(960px) {
        font-size: 60px;
        line-height: 1;
        width: 650px;
      }

      @include max(540px) {
        font-size: 32px;
      }
    }

    p {
      font-size: 26px;
      letter-spacing: -0.4px;
      line-height: (33 / 26);
      font-weight: 500;
      width: 600px;
      max-width: 100%;

      @include max(540px) {
        font-size: 20px;
      }
    }
  }

  &__featured-case {
    position: absolute;
    bottom: 50px;
    right: 50px;
    width: 450px;
    display: flex;
    flex-direction: row;
    color: white;
    cursor: pointer;
    transition: 0.2s background-color ease, 0.2s box-shadow ease;
    box-shadow: 0 0 0 10px rgba(white, 0);

    @include max(560px) {
      width: initial;
      left: 50px;
    }

    @include max(540px) {
      left: 10px;
      right: 10px;
      bottom: 10px;
    }

    &:hover {
      background-color: rgba(white, 0.1);
      box-shadow: 0 0 0 20px rgba(white, 0.1);
    }

    &__content,
    &__picture {
      flex: 1;
    }

    img {
      width: 100%;
      vertical-align: top;
    }

    &__content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-right: 20px;

      @include max(540px) {
        margin-right: 0;
        padding: 20px;
        border: 1px solid rgba(white, 0.2);
      }

      h4 {
        font-size: 20px;
        font-weight: 600;
        line-height: (28 / 20);
        margin: 10px 0;
      }
    }

    &__client {
      font-size: 14px;
      font-weight: 300;
      line-height: (22 / 14);
      opacity: 0.6;
    }

    &__services {
      font-size: 16px;
      font-weight: 600;
      line-height: (22 / 16);

      & > span {
        margin-right: 20px;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    &__picture {
      @include max(540px) {
        display: none;
      }
    }
  }
}
